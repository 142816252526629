import React, { useState, useRef, useEffect } from 'react';
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';
import * as EmailValidator from 'email-validator';
import qs from 'qs';

import Input, { InputType } from '../primitives/Input/Input';
import Button from '../primitives/Button/Button';
import { IconTypes } from '../Icon/Icon';
import { useLocaleContext } from '../../context/Locale';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { LocaleNameSpaceKeys } from '../../localization/translations';
import { getPathFromRouteKey } from '../../shared/utils/getPathFromRouteKey';
import RouteKeys from '../../../config/RouteKeys';
import { LocaleTag } from '../../../config/locales';
import Link from '../Link/Link';

import './style.scss';

interface OwnProps {
  t: TFunction;
}

enum FormDataKey {
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  firmName = 'firmName',
  consentOne = 'consentOne',
  consentTwo = 'consentTwo',
  recaptcha = 'reCaptcha',
}

enum FormFieldMapping {
  firstName = 'sgE-50172986-1-20',
  lastName = 'sgE-50172986-1-21',
  email = 'sgE-50172986-1-22',
  preferredLanguage = 'sgE-50172986-1-23',
  firmName = 'sgE-50172986-1-24',
  consentOne = 'sgE-50172986-1-2',
  consentTwo = 'sgE-50172986-1-17',
  postalCode = 'sgE-50172986-1-27',
}

type Props = OwnProps;

const ComplaintsFormForm: React.FC<Props> = ({ t }) => {
  const { activeLocaleTag } = useLocaleContext();
  const recaptchaRef = React.createRef<ReCAPTCHA>();
  const reCaptchaTimerRef = useRef('');

  const initialFormState = {
    firstName: {
      isInvalid: false,
      validationMessage: '',
      value: '',
    },
    lastName: {
      isInvalid: false,
      validationMessage: '',
      value: '',
    },
    email: {
      isInvalid: false,
      validationMessage: '',
      value: '',
    },
    firmName: {
      isInvalid: false,
      validationMessage: '',
      value: '',
    },
    postalCode: {
      isInvalid: false,
      validationMessage: '',
      value: '',
    },
    consentOne: {
      isInvalid: false,
      validationMessage: '',
      value: '',
    },
    consentTwo: {
      isInvalid: false,
      validationMessage: '',
      value: '',
    },

    reCaptcha: {
      isInvalid: false,
      validationMessage: '',
      value: '',
      ts: '',
    },
  };

  const [formValues, setFormValues] = useState<{
    [key: string]: { isInvalid: boolean; validationMessage: string; value: string; ts?: string };
  }>(initialFormState);

  const [areFormValuesInvalid, setAreFormValuesInvalid] = useState(false);

  const [formSubmissionResponse, setFormSubmissionResponse] = useState({
    submitted: false,
    shouldDisplayMessage: false,
  });

  const handleFormChange = (val: any, id: FormDataKey) => {
    setFormValues({
      ...formValues,
      [id]: {
        ...formValues[id],
        value: val,
      },
    });
  };

  const handleCheckboxChange = (val: any, id: FormDataKey) => {
    setFormValues({
      ...formValues,
      [id]: {
        ...formValues[id],
        value: formValues[id].value ? '' : val,
      },
    });
  };

  const timestamp = () => {
    const response = document.getElementById('g-recaptcha-response');
    // @ts-ignore
    // eslint-disable-next-line eqeqeq
    if (response == null || response.value.trim() == '') {
      reCaptchaTimerRef.current = JSON.stringify(new Date().getTime());
    }
  };

  const handleCaptchaOnChange = (value: any) => {
    setFormValues((prevState) => ({
      ...prevState,
      reCaptcha: {
        ...prevState.reCaptcha,
        isInvalid: false,
        value,
      },
    }));
  };

  useEffect(() => {
    const interval = setInterval(timestamp, 500);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (formSubmissionResponse.shouldDisplayMessage && formSubmissionResponse.submitted) {
      const message = document.getElementById('success-message');

      if (message) {
        message.scrollIntoView({ block: 'end' });
      }
    }
  }, [formSubmissionResponse]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const newFormValues = { ...formValues };
    const formValueFields = Object.keys(newFormValues);
    let firstErrorField = '';

    formValueFields.forEach((element) => {
      const currentField = newFormValues[element as FormDataKey];

      if (!currentField.value) {
        currentField.isInvalid = true;
        currentField.validationMessage = t(`currentDepositRates.form.validationMessage.${element}`);
        if (!firstErrorField) firstErrorField = element;
      } else if (element === 'email' && !EmailValidator.validate(currentField.value)) {
        currentField.isInvalid = true;
        if (!firstErrorField) firstErrorField = element;

        currentField.validationMessage = t(
          `${LocaleNameSpaceKeys.CONTACT_US}:contactUs.form.email.validationMessage.invalidCharacters`
        );
      } else {
        currentField.isInvalid = false;
        currentField.validationMessage = '';
      }
    });

    const newAreFormValuesInvalid = formValueFields.some(
      (field) => (newFormValues as any)[field].isInvalid
    );

    setFormValues(newFormValues);
    setAreFormValuesInvalid(newAreFormValuesInvalid);

    if (!newAreFormValuesInvalid) {
      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
      }

      const finalObject = {
        [FormFieldMapping.firstName]: formValues.firstName.value,
        [FormFieldMapping.lastName]: formValues.lastName.value,
        [FormFieldMapping.email]: formValues.email.value,
        [FormFieldMapping.firmName]: formValues.firmName.value,
        [FormFieldMapping.consentOne]: formValues.consentOne.value,
        [FormFieldMapping.consentTwo]: formValues.consentTwo.value,
        [FormFieldMapping.postalCode]: formValues.postalCode.value,
        [FormFieldMapping.preferredLanguage]: activeLocaleTag === LocaleTag.EN ? '10023' : '10024',
        sg_navchoice: '',
        sg_currentpageid: '1',
        sg_surveyident: '50172986',
        sg_sessionid: '',
        sg_high_contrast: 'false',
        sg_cint_panelist: '',
        sg_referer: 'https://app.alchemer-ca.com/distribute/share/id/50172986',
        sg_interactionlevel: '1',
        sg6ae376f770e48daa0d4814bc2e986b0f: '',
        sGizmoSubmitButton: 'Submit',
      };

      await fetch('https://survey.haventreebank.com/s3/Deposit-Web-Form-to-Receive-Our-Updates', {
        method: 'POST',
        body: qs.stringify(finalObject),
        credentials: 'same-origin',
        mode: 'no-cors',
        // @ts-ignore
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Access-Control-Allow-Origin': '*',
          Accept:
            'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9',
          Origin: null,
          'Upgrade-Insecure-Requests': 1,
        },
      })
        .then(() => {
          setFormSubmissionResponse({
            submitted: true,
            shouldDisplayMessage: true,
          });

          setFormValues(() => initialFormState);
        })
        .catch((err) => {
          console.error(`Failed: `, err);
          setFormSubmissionResponse({
            submitted: false,
            shouldDisplayMessage: true,
          });
        });

      return true;
    }

    if (firstErrorField) {
      let inputField;

      if (firstErrorField === FormDataKey.recaptcha) {
        inputField = document.querySelector('recaptcha-checkbox-border');
      } else {
        inputField = document.getElementById(firstErrorField);
      }

      if (inputField) {
        (inputField as HTMLElement).focus();
      }
    }

    return false;
  };

  return formSubmissionResponse.shouldDisplayMessage && formSubmissionResponse.submitted ? (
    <div className="ComplaintsForm__generic-gap">
      <p className="ComplaintsForm__success-message" id="success-message" aria-live="polite">
        <Trans>{t(`currentDepositRates.form.submissionMessage`)}</Trans>
      </p>
    </div>
  ) : (
    <form onSubmit={handleSubmit} className="DepositSurveyForm DepositSurveyForm__generic-gap">
      <Input
        id="firstName"
        className="text-input"
        isInvalid={formValues.firstName.isInvalid}
        validationMessage={formValues.firstName.validationMessage}
        options={{
          type: InputType.TEXT,
          label: t('currentDepositRates.form.firstName'),
          inputProps: {
            name: 'firstName',
            value: formValues.firstName.value,
          },
        }}
        required
        onChange={handleFormChange}
      />

      <Input
        id="lastName"
        className="text-input"
        isInvalid={formValues.lastName.isInvalid}
        validationMessage={formValues.lastName.validationMessage}
        options={{
          type: InputType.TEXT,
          label: t('currentDepositRates.form.lastName'),
          inputProps: {
            name: 'lastName',
            value: formValues.lastName.value,
          },
        }}
        required
        onChange={handleFormChange}
      />

      <Input
        id="email"
        className="text-input"
        isInvalid={formValues.email.isInvalid}
        validationMessage={formValues.email.validationMessage}
        options={{
          type: InputType.TEXT,
          label: t('currentDepositRates.form.email'),
          inputProps: {
            name: 'email',
            value: formValues.email.value,
          },
        }}
        required
        onChange={handleFormChange}
      />

      <Input
        id="firmName"
        className="text-input"
        isInvalid={formValues.firmName.isInvalid}
        validationMessage={formValues.firmName.validationMessage}
        options={{
          type: InputType.TEXT,
          label: t(`currentDepositRates.form.firmName`),
          inputProps: {
            name: 'firmName',
            value: formValues.firmName.value,
          },
        }}
        required
        onChange={handleFormChange}
      />

      <Input
        id="postalCode"
        className="text-input"
        isInvalid={formValues.postalCode.isInvalid}
        validationMessage={formValues.postalCode.validationMessage}
        options={{
          type: InputType.TEXT,
          label: t(`currentDepositRates.form.postalCode`),
          inputProps: {
            name: 'postalCode',
            value: formValues.postalCode.value,
          },
        }}
        required
        onChange={handleFormChange}
      />

      <Input
        id="consentOne"
        className="consent-checkbox"
        isInvalid={formValues.consentOne.isInvalid}
        validationMessage={formValues.consentOne.validationMessage}
        options={{
          type: InputType.CHECKBOX,
          label: t(`currentDepositRates.form.consentOne`),
          inputProps: {
            name: 'consentOne',
            value: '10001',
          },
        }}
        required
        onChange={handleCheckboxChange}
      />

      <Input
        id="consentTwo"
        className="consent-checkbox"
        isInvalid={formValues.consentTwo.isInvalid}
        validationMessage={formValues.consentTwo.validationMessage}
        options={{
          type: InputType.CHECKBOX,
          label: (
            <Trans i18nKey="currentDepositRates.form.consentTwo" t={t}>
              <Link to={getPathFromRouteKey(RouteKeys.PRIVACY_STATEMENT)} />
              <Link to={getPathFromRouteKey(RouteKeys.ELECTRONIC_ACCESS_AGREEMENT)} />
            </Trans>
          ),
          inputProps: {
            name: 'consentTwo',
            value: '10013',
          },
        }}
        required
        onChange={handleCheckboxChange}
      />

      <div className="ComplaintsForm__recaptcha-wrapper">
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={process.env.GATSBY_WEB_TO_CASE_SALESFORCE_CAPTCHA_KEY_VALUE || ''}
          onChange={handleCaptchaOnChange}
          hl={activeLocaleTag}
          theme="dark"
        />
      </div>

      {formSubmissionResponse.shouldDisplayMessage && !formSubmissionResponse.submitted && (
        <ErrorMessage className="ComplaintsForm__generic-error-message">
          {t(`${LocaleNameSpaceKeys.CONTACT_US}:contactUs.form.submissionValidation.error`)}
        </ErrorMessage>
      )}

      {areFormValuesInvalid && (
        <ErrorMessage className="ComplaintsForm__generic-error-message">
          {t(`${LocaleNameSpaceKeys.CONTACT_US}:contactUs.form.genericErrorMessage`)}
        </ErrorMessage>
      )}

      <Button
        id="submit"
        type="submit"
        iconOptions={{ icon: IconTypes.SEND_MAIL }}
        styleOptions={{ isInline: true }}
      >
        {t(`${LocaleNameSpaceKeys.CONTACT_US}:contactUs.form.submitLabel`)}
      </Button>
    </form>
  );
};

export default ComplaintsFormForm;
