import React from 'react';
import { PageProps } from 'gatsby';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';

import Layout, { LayoutOptions, HeroSectionColors } from '../../components/Layout/Layout';
import { LocaleNameSpaceKeys } from '../../localization/translations';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import { getPathFromRouteKey } from '../../shared/utils/getPathFromRouteKey';
import RouteKeys from '../../../config/RouteKeys';
import Icon, { IconTypes } from '../../components/Icon/Icon';
import ExternalLink from '../../components/ExternalLink/ExternalLink';
import CommonData from '../../data/common/common';
import { useLocaleContext } from '../../context/Locale';
import InfoBlock from '../../components/InfoBlock/InfoBlock';
import SurveyForm from '../../components/Deposits/SurveyForm';
import { LocaleTag } from '../../../config/locales';
import './style.scss';

type Props = PageProps & WithTranslation;

const Deposits: React.FC<Props> = (props: Props) => {
  const { t } = props;
  const { activeLocaleTag } = useLocaleContext();
  const layoutOptions: LayoutOptions = {
    seo: {
      title: t('deposits.seo.title'),
    },
    page: {
      title: t('deposits.heading'),
    },
    heroSection: {
      options: {
        accentColor: HeroSectionColors.MANGO_YELLOW,
        heroImage: {
          fileName: 'hero-deposits.jpg',
          alt: 'Image',
        },
      },
    },
  };

  const cdicBrochure = CommonData.websites.cdicBrochure[activeLocaleTag];
  const gicEmail = t(`${LocaleNameSpaceKeys.COMMON}:emails.gic`);
  const gicPhone = CommonData.telephoneNumber.gic;

  return (
    <Layout options={layoutOptions} className="Deposits">
      <div className="grid-container Deposits__generic-gap">
        <div className="row row-wrap Deposits__row-flex">
          <section className="column column-60">
            <div className="Deposits__generic-gap">
              <p>
                <Trans t={t} i18nKey="deposits.introParagraph" />
              </p>
            </div>
            <div className="Deposits__generic-gap">
              <h2 className="h4">{t('deposits.gicBlock.heading')}</h2>
              <p>{t('deposits.gicBlock.body')}</p>

              <div className="grid-container Deposits__generic-gap">
                <div className="row row-wrap">
                  <div className="column column-40">
                    <InfoBlock
                      heading={
                        <h3 className="h5">{t('deposits.gicBlock.infoBlocks.broker.heading')}</h3>
                      }
                      icon={IconTypes.MEET_THE_TEAM}
                    >
                      <Trans
                        t={t}
                        i18nKey="deposits.gicBlock.infoBlocks.broker.body"
                        values={{
                          email: gicEmail,
                          phone: gicPhone,
                        }}
                      >
                        <ExternalLink
                          href={`mailto:${gicEmail}`}
                          aria-label={`${t(`${LocaleNameSpaceKeys.COMMON}:email`)} ${gicEmail}`}
                        />
                        <ExternalLink
                          href={`tel:${gicPhone}`}
                          aria-label={`${t(`${LocaleNameSpaceKeys.COMMON}:tollFree`)} ${gicPhone}`}
                        />
                      </Trans>
                    </InfoBlock>
                  </div>
                  <div className="column column-50">
                    <InfoBlock
                      heading={
                        <h3 className="h5">{t('deposits.gicBlock.infoBlocks.client.heading')}</h3>
                      }
                      icon={IconTypes.DOLLAR}
                    >
                      {t('deposits.gicBlock.infoBlocks.client.body')}
                    </InfoBlock>
                  </div>
                </div>
              </div>
            </div>
            <div className="Deposits__generic-gap">
              <h2 className="h4">{t('deposits.cdicBlock.heading')}</h2>
              <p>
                <Trans t={t} i18nKey="deposits.cdicBlock.body">
                  <ExternalLink href={cdicBrochure} />
                </Trans>
              </p>
              <div className="deposits-cdic-logo">
                <ExternalLink href={CommonData.websites.cdic[activeLocaleTag]}>
                  <div className="cdic-logo-wrapper">
                    <Icon
                      type={activeLocaleTag === LocaleTag.EN ? IconTypes.CDIC : IconTypes.SADC}
                    />
                  </div>
                </ExternalLink>
              </div>
            </div>
          </section>
          <aside className="column column-33">
            <div className="Deposits__generic-gap Deposits__page-menu">
              <ButtonLink
                to={getPathFromRouteKey(RouteKeys.DEPOSITS.CURRENT_DEPOSIT_RATES)}
                iconOptions={{
                  icon: IconTypes.ARROW_NEXT,
                }}
              >
                {t('deposits.currentDeposits.buttonLabel')}
              </ButtonLink>
              <ButtonLink
                to={getPathFromRouteKey(RouteKeys.DEPOSITS.FAQ)}
                iconOptions={{
                  icon: IconTypes.ARROW_NEXT,
                }}
              >
                FAQ
              </ButtonLink>
            </div>
            <div className="CurrentDepositRates__generic-gap">
              <InfoBlock
                heading={<h2 className="h5">{t('currentDepositRates.receiveUpdates.heading')}</h2>}
              >
                <h3 className="h6">{t('currentDepositRates.receiveUpdates.headingTwo')}</h3>
                <p>
                  <Trans t={t} i18nKey="currentDepositRates.receiveUpdates.body" />
                </p>
              </InfoBlock>
              <SurveyForm t={t} />
            </div>
          </aside>
        </div>
      </div>
    </Layout>
  );
};

export default withTranslation(LocaleNameSpaceKeys.DEPOSITS)(Deposits);
